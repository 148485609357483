import PopupFollowOfficialAccountsService from '@/components/Popup/PopupFollowOfficialAccountsService'
import UserApi from 'pk/api/UserApi'
import StatusFactory from 'pk/factory/StatusFactory'
import IOService from 'pk/service/IOService'
import { reactive } from 'vue'
import StatusService from '../StatusService'
import CacheService from '../CacheService'
import PopupBindPhoneService from '@/components/Popup/PopupBindPhoneService'
import VersionService from 'pk/service/VersionService'
import { formatDate, isWX, phoneDesensitization } from 'pk/service/Tools/ToolsService'
import UserPersonalService from './UserPersonalService'
import UserEnterpriseService from './UserEnterpriseService'
import UserControlService from './UserControlService'
import RouterService from '@/service/RouterService'
import UIService from 'pk/service/UIService'
import WXService from '@/pk/src/service/WXService'
import PopupBaseUserInfoService from '@/components/Popup/PopupBaseUserInfoService'

export interface RoleItem {
  name: string
  display_name: string
}

class UserService {
  modelName = 'User'
  api!: UserApi
  info = {
    id: '',
    nickname: '',
    username: '',
    phone: '',
    head_url: '',
    city: 0,
    roles: [] as RoleItem[],
    permissions: [],
    is_follow_official_account: 0,
    register_at: '',
    _roleName: '',
    _phone: '',
    _isWxAuth: false,
    _isVip: false,
    _isGiveVip: false,
    _vipEndTime: ''
  } as {
    [key: string]: any
  }

  // 是否显示关注公众号广告
  isShowFollowAd = true
  StatusService!: StatusFactory
  PopupBaseUserInfoService!: PopupBaseUserInfoService

  constructor() {
    this.api = new UserApi()
    this.updateData(CacheService.user.getAll())
    this.StatusService = new StatusFactory()
    this.PopupBaseUserInfoService = new PopupBaseUserInfoService()
  }

  isLogin() {
    const res = !!this.info.id
    if (!res) {
      this.logout()
    }
    return res
  }

  login() {
    return Promise.resolve()
      .then(() => {
        return reactive(new PopupBindPhoneService()).open()
      })
      .then(() => {
        return this.PopupBaseUserInfoService.checkOpen().catch(() => {})
      })
      .then(() => {
        VersionService.getAppConfig()
        if (!this.info._isWxAuth) {
          WXService.auth('snsapi_userinfo')
        }
      })
  }

  checkLogin() {
    return Promise.resolve().then(() => {
      return reactive(new PopupBindPhoneService()).checkOpen()
    })
  }

  todayFirstLogin() {
    return Promise.resolve().then(() => {
      const today = formatDate()
      const firstLoginDate = CacheService.user.get('_firstLoginDate')
      if ((!firstLoginDate || today > firstLoginDate) && this.isLogin()) {
        return this.api
          .todayFirstLogin()
          .then(() => {
            CacheService.user.set('_firstLoginDate', today)
            this.getUserInfo()
          })
          .catch((err) => {
            if (err) {
              if (err.code === 424) {
                CacheService.user.set('_firstLoginDate', today)
              } else {
                CacheService.user.remove('_firstLoginDate')
              }
            }
          })
      }
    })
  }

  logout() {
    CacheService.clearAll()
    this.clearInfo()
    UserPersonalService.clearInfo()
    UserEnterpriseService.clearInfo()
    return Promise.resolve({ message: '注销成功' })
  }

  getUserInfo({ simple = false } = {}) {
    return this.api.getUserInfo().then((res) => {
      this.updateData(res.data)
      if (!simple) {
        this.getExtraUserInfo()
      }
    })
  }

  getExtraUserInfo() {
    return Promise.all([UserPersonalService.show(), UserEnterpriseService.show(), UserControlService.show()])
  }

  updateAvatar(head_url: string) {
    return this.api.updateAvatar(head_url).then(() => {
      this.updateData({ head_url })
    })
  }

  updateData(params: any) {
    CacheService.user.setAll(params)
    Object.assign(this.info, params)
    if (this.info.is_follow_official_account) {
      this.isShowFollowAd = false
    }
    if (this.info.roles.length > 0) {
      this.info._roleName = this.info.roles[0].display_name
    }
    if (this.info.phone) {
      this.info._phone = phoneDesensitization(this.info.phone)
    }
  }

  clearInfo() {
    this.info.id = ''
    this.info.nickname = ''
    this.info.username = ''
    this.info.phone = ''
    this.info.head_url = ''
    this.info.city = 0
    this.info.roles = []
    this.info.permissions = []
    this.info.is_follow_official_account = 0
    this.info._roleName = ''
    this.info.industries = []
    this.info._isVip = false
    this.info._vipEndTime = ''
  }

  checkAuth() {
    return this.info.roles.some((res: RoleItem) => res.name === 'Personal Auth' || res.name === 'Enterprise Auth')
  }

  checkPersonalAuth() {
    return this.info.roles.some((res: RoleItem) => res.name === 'Personal Auth')
  }

  checkEnterpriseAuth() {
    return this.info.roles.some((res: RoleItem) => res.name === 'Enterprise Auth')
  }

  checkVIP() {
    return this.info.roles.some((res: RoleItem) => res.name === 'Personal VIP' || res.name === 'Enterprise VIP')
  }

  checkPersonalVIP() {
    return this.info.roles.some((res: RoleItem) => res.name === 'Personal VIP')
  }

  checkEnterpriseVIP() {
    return this.info.roles.some((res: RoleItem) => res.name === 'Enterprise VIP')
  }

  checkEnterpriseMember() {
    return this.info.roles.some((res: RoleItem) => res.name === 'Enterprise Member')
  }

  setInviteUser() {
    const iu = RouterService.params('iu')
    return Promise.resolve().then(() => {
      if (iu) {
        return this.api.setInviteUser(iu)
      }
    })
  }

  checkOfficialAccounts(modelName?: string): Promise<any> {
    if (!isWX) {
      return Promise.resolve()
    }
    return this.api
      .checkOfficialAccounts()
      .then(() => {
        this.info.is_follow_official_account = 1
      })
      .catch((err) => {
        this.info.is_follow_official_account = 0
        if (err.code === StatusService.checkFollowOfficialAccounts) {
          reactive(new PopupFollowOfficialAccountsService()).open({ modelName })
        }
        throw err
      })
  }

  getInviteList(page: number) {
    return this.api.getInviteList(page)
  }

  getInviteUser() {
    return this.api.getInviteUser(RouterService.params('iu'))
  }

  bindPhone(params: { phone: string; code: string; is_force?: number }): any {
    return this.api
      .bindPhone(params)
      .then((res) => {
        return this.getUserInfo().then(() => res)
      })
      .catch((err) => {
        if (err.code === this.StatusService.checkForceBindPhone) {
          return UIService.confirm(err.message).then(() => this.bindPhone({ ...params, is_force: 1 }))
        }
        return Promise.reject(err)
      })
  }

  phoneLogin(params: { phone: string; code: string; is_force?: number }): any {
    return this.api
      .phoneLogin(params)
      .then((res) => {
        this.updateData(res.data)
        return this.getUserInfo({ simple: true })
          .then(() => this.getExtraUserInfo())
          .then(() => res)
      })
      .catch((err) => {
        if (err.code === this.StatusService.checkForceBindPhone) {
          return UIService.confirm(err.message).then(() => this.phoneLogin({ ...params, is_force: 1 }))
        }
        return Promise.reject(err)
      })
  }

  passwordLogin(params: { phone: string; password: string }) {
    return this.api.passwordLogin(params).then((res) => {
      this.updateData(res.data)
      return this.getUserInfo({ simple: true })
        .then(() => this.getExtraUserInfo())
        .then(() => res)
    })
  }

  updateUserInfo(params: { industries?: number[]; city?: number }) {
    return IOService.post('/user/updateUserInfo', params)
  }

  checkInfoPublish(params: { _model: string }) {
    return IOService.get('/user/checkInfoPublish', params)
  }

  checkHrResumeInfoPublish(params: { _model: string }) {
    return IOService.get('/user/checkHrResumeInfoPublish', params)
  }

  checkWechatAuth() {
    return Promise.resolve()
      .then(() => {
        if (!this.isLogin()) {
          return this.login()
        }
      })
      .then(() => {
        if (!this.info._isWxAuth) {
          return WXService.auth('snsapi_userinfo')
        }
      })
  }
}

export default reactive(new UserService())
