import { defineComponent } from 'vue';
import PopupContainer from 'pk/components/Popup/PopupContainer.vue';
import PopupAreaService from 'pk/components/Popup/PopupAreaService';
import PopupIndustryService from '@/components/Popup/PopupIndustryService';
import UserService from '@/service/User/UserService';
import ValidateService from 'pk/service/ValidateService';
export default defineComponent({
  components: {
    PopupContainer
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: '行业与地区'
        };
      }
    }
  },
  data() {
    return {
      PopupAreaService: new PopupAreaService(),
      PopupIndustryService: new PopupIndustryService(),
      form: {
        city: 0,
        industries: []
      },
      formFields: ValidateService.genRules({
        city: {
          prop: 'city',
          label: '地区',
          rules: [ValidateService.required]
        },
        industries: {
          prop: 'industries',
          label: '行业',
          rules: [ValidateService.required]
        }
      })
    };
  },
  methods: {
    handleSubmit() {
      return UserService.updateUserInfo(this.form).then(() => UserService.getUserInfo({
        simple: true
      })).then(() => {
        this.popupService.success({});
        return Promise.resolve({
          message: '设置成功'
        });
      });
    }
  }
});