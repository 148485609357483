import { defineComponent } from 'vue';
export default defineComponent({
  inheritAttrs: false,
  props: {
    src: String
  },
  computed: {
    innerSrc() {
      return `${this.src}!200`;
    }
  }
});