import UserService from '@/service/User/UserService'
import PopupAbstract from 'pk/components/Popup/PopupAbstract'
import { markRaw } from 'vue'
import PopupBaseUserInfo from './PopupBaseUserInfo.vue'

export default class PopupBaseUserInfoService extends PopupAbstract {
  public name = 'PopupBaseUserInfoService'
  protected component = markRaw(PopupBaseUserInfo)

  public checkOpen () {
    if (!UserService.isLogin()) {
      return Promise.resolve()
    }
    if (UserService.info.city && UserService.info.industries.length > 0) {
      return Promise.resolve()
    }
    return super.open()
  }
}
